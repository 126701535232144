
/*
PLUGIN SPREJME DATUM IN CUSTOM_ID OBJEKTA ALI GRUPE (PARAMETER OBJECT ALI GROUP)
*/

(function( $ ) {
    var container;
    var url;
    var object;
    var group;

    var currentDate;

    var dialogDiv;

    var titles = {
        "available" : "Prosto",
        "reserved" : "Zasedeno",
        "locked" : "Ni na voljo",
    };

    $.fn.lygym = function(options) {
        var params = {
            container: $(this),
            url: options.url,
            object: options.object,
            group: options.group
        };

        getJson(options, params);
    };

    function getJson( options, params ) {
        $.getJSON(params.url + "getJson", {date: options.date, object: params.object, group: params.group}, function(data) {
            currentDate = options.date;
            if(data.header) {
                createGrid(data, params);
            } else {
                createGridGroup(data, params);
            }
            createDatepicker(data.dates, params);
            createTooltips();


            $(".item.reserved").tooltip(
                function() {
                    var classes = $(this).attr("class");
                    var classesString = "." + classes.replace(/ /g, '.');
                    $(classesString).each(function() {
                        $(this).addClass("hover");
                    });
                }, function() {
                    $(".item").removeClass("hover");
                }
            );
        });
    };

    function createGrid(data, params) {
        var firstRow = data.header;
        if(params.object == "atl-s-object") {
            var atletskiHeader = ["steza-1", "steza-2", "steza-3", "steza-4", "steza-5", "skok v daljino-1", "skok v daljino-2", "skok v višino-2", "metališče"];
            firstRow = atletskiHeader;
        }

        var html = '<div class="row header"><div class="item hour" style="width:calc(100% / ('+ firstRow.length +' + 1))"></div>';
        for(var i = 0; i < firstRow.length; i++) {
            html += '<div class="item" style="width:calc(100% / ('+ firstRow.length +' + 1))">' + firstRow[i] + '</div>';
        }
        html += '</div>';
        //izbrišemo podatke, ki jih ne rabimo
        $.each(data, function(key, value) {
            if(key == 'dates' || key == 'header' || key == "legend" || key == "title" || key == "date") return true;
            html += '<div class="row">';
            html += '<div class="item hour" style="width:calc(100% / ('+ firstRow.length +' + 1))">' + key + '</div>';
            if(typeof value === 'object') {
                $.each(value, function (innerKey, innerValue) {
                    if(typeof innerValue === 'object') {
                        var divClass = "reserved";
                        var divTitle = innerValue.short;
                        var divShort = innerValue.short
                        var divName = innerValue.name
                        html += '<div class="item ' + divClass + ' ' + innerValue.reservationId + '" title="' + divTitle +'" style="width:calc(100% / ('+ firstRow.length +' + 1));background-color:#'+innerValue.customerColor+'" data-short="' + divShort + '" data-name="' + divName + '"></div>';
                    } else {
                        var divClass = innerValue;
                        var divTitle = titles[innerValue];
                        html += '<div class="item ' + divClass + '" title="' + divTitle +'" style="width:calc(100% / ('+ firstRow.length +' + 1))"></div>';
                    }
                });
            }
            html += '</div>';
        });
        params.container.find(".lygymContainer").remove();
        var parent = $('<div class="lygymContainer"></div>').appendTo(params.container);
        parent.append(html);
    }

    function createGridGroup(data, params) {
        var longestValues = [];
        $.each(data, function(key, value) {
            if(value) {
                var splitKey = key.split("_");
                if (splitKey[0] == "header" && value.length > longestValues.length) {
                    longestValues = value;
                }
            }
        });

        var html = "";
        var firstRow = "";
        //izbrišemo podatke, ki jih ne rabimo
        $.each(data, function(key, value) {
            if(value) {
                if(value.length != 0) {
                    var splitKey = key.split("_");
                    if (splitKey[0] == "header") {
                        firstRow = value;
                        //html += '<div class="row header"><div class="item hour" style="width:calc(100% / ('+ longestValues.length +' + 1))">' + splitKey[2] + '</div>';
                        html += '<div class="row header"><div class="item hour" style="width:50px;font-weight:bold">' + splitKey[2] + '</div>';
                        for (var i = 0; i < firstRow.length; i++) {
                            //html += '<div class="item" style="width:calc(100% / ('+ firstRow.length +' + 1))">' + firstRow[i] + '</div>';
                            html += '<div class="item" style="width:calc((100% - (51px * 1)) / ' + firstRow.length + ')">' + firstRow[i] + '</div>';
                        }
                        html += '</div>';
                    } else {
                        if (key == 'dates') return true;
                        html += '<div class="row">';
                        //html += '<div class="item hour" style="width:calc(100% / (' + longestValues.length + ' + 1))">' + key + '</div>';
                        html += '<div class="item hour" style="width:50px;">' + key + '</div>';
                        $.each(value, function (innerKey, innerValue) {
                            if (typeof innerValue === 'object') {
                                var divClass = "reserved";
                                var divTitle = innerValue.short;
                                var divShort = innerValue.short;
                                var divName = innerValue.name;
                                //html += '<div class="item ' + divClass + '" title="' + divTitle + '" style="width:calc(100% / (' + firstRow.length + ' + 1))" data-short="' + divShort + '" data-name="' + divName + '"></div>';
                                html += '<div class="item ' + divClass + ' ' + innerValue.reservationId + '" title="' + divTitle + '" style="width:calc((100% - (51px * 1)) / ' + firstRow.length + ')" data-short="' + divShort + '" data-name="' + divName + '"></div>';
                            } else {
                                var divClass = innerValue;
                                var divTitle = titles[innerValue];
                                //html += '<div class="item ' + divClass + '" title="' + divTitle + '" style="width:calc(100% / (' + firstRow.length + ' + 1))"></div>';
                                html += '<div class="item ' + divClass + '" title="' + divTitle + '" style="width:calc((100% - (51px * 1)) / ' + firstRow.length + ')"></div>';
                            }
                        })
                    }
                    html += '</div>';
                } else {
                    html += '<div class="row">';
                    html += '<div class="item hour" style="width:50px;">' + key + '</div>';
                    html += '<div class="item locked" title="' + titles["locked"] + '" style="width:calc((100% - (51px * 1)))"></div>';
                    html += '</div>';
                }
            }
        });
        params.container.find(".lygymContainer").remove();
        var parent = $('<div class="lygymContainer"></div>').appendTo(params.container);
        // console.log(parent);
        parent.append(html);
    }

    function createDatepicker(dates, params) {
        params.container.find(".dates").remove();
        var parentTop = $('<div class="dates d-flex align-items-center justify-content-between"></div>').prependTo(params.container);
        var parentBottom = $('<div class="dates d-flex align-items-center justify-content-between"></div>').appendTo(params.container);
        var calendarTop = $('<div class="calendar" title="odpri koledar"><span class="icon-calendar"></span></div>').appendTo(parentTop);
        var calendarBottom = $('<div class="calendar" title="odpri koledar"><span class="icon-calendar"></span></div>').appendTo(parentBottom);
        var prevTop = $('<div class="prev d-flex align-items-center" data-date="' + dates.prev + '"> <span class="icon-angle-left"></span> </div>').appendTo(parentTop);
        var prevBottom = $('<div class="prev d-flex align-items-center" data-date="' + dates.prev + '"> <span class="icon-angle-left"></span> </div>').appendTo(parentBottom);
        var currentTop = $('<div class="current">' + dates.currentTitle + '</div>').appendTo(parentTop);
        var currentBottom = $('<div class="current">' + dates.currentTitle + '</div>').appendTo(parentBottom);
        var nextTop = $('<div class="next d-flex align-items-center" data-date="' + dates.next + '"> <span class="icon-angle-right"></span> </div>').appendTo(parentTop);
        var nextBottom = $('<div class="next d-flex align-items-center" data-date="' + dates.next + '"> <span class="icon-angle-right"></span> </div>').appendTo(parentBottom);
        var printContainerTop = $('<div class="printContainer top"></i>').appendTo(parentTop);
        var printContainerBottom = $('<div class="printContainer bottom"></i>').appendTo(parentBottom);
        var printTop = $('<div class="print" title="Natisni" data-href="/short/1"><span class="icon-print" class="printSchedule"></span></div>').appendTo(printContainerTop);
        var printBottom = $('<div class="print" title="Natisni" data-href="/short/1"><span class="icon-print" class="printSchedule"></span></div>').appendTo(printContainerBottom);

        prevTop.on("click", function() {
            changeDate($(this).data("date"), params);
        });
        prevBottom.on("click", function() {
            changeDate($(this).data("date"), params);
        });
        nextTop.on("click", function() {
            changeDate($(this).data("date"), params);
        });
        nextBottom.on("click", function() {
            changeDate($(this).data("date"), params);
        });

        calendarTop.flatpickr({
            defaultDate: "today",
            dateFormat: "Y-m-d",
            altInputClass : "invisible",
            "locale": "sl",
            onChange: function(selectedDates, dateStr, instance) {
                changeDate(dateStr, params);
            }
        });
        calendarBottom.flatpickr({
            defaultDate: "today",
            dateFormat: "Y-m-d",
            altInputClass : "invisible",
            "locale": "sl",
            onChange: function(selectedDates, dateStr, instance) {
                changeDate(dateStr, params);
            }
        });

        //dodamo legendo
        params.container.find(".legend").remove();
        var html = '<div class="legend d-flex align-items-center"><div class="available">Prosto</div><div class="locked">Ni na voljo</div><div class="reserved">Rezervirano</div></div>';
        params.container.prepend(html);

        var dialogHtml = '<div id="dialog">';
        dialogHtml += '<div><div class="button" data-href="/short/1">Izvozi s short</div><div class="button" data-href="">Izvozi s številkami</div></div>';
        dialogHtml += '</div>';
        dialogDiv = $(dialogHtml).appendTo(params.container.find(".printContainer"));

        //dialogDiv.dialog({
        //    autoOpen: false,
        //    buttons: [
        //        {
        //            text: "Izvozi s short",
        //            click: function() {
        //                var plusHref = "";
        //                if(typeof currentDate !== 'undefined') {
        //                    plusHref ="/date/" + currentDate
        //                }
        //                var place = '/object/' + object;
        //                if(typeof group !== 'undefined') {
        //                    place = '/group/' + group;
        //                }
        //                location.href = url + "printPdf" + place + plusHref + "/short/1";
        //                $( this ).dialog( "close" );
        //            }
        //        }, {
        //            text: "Izvozi s številkami",
        //            click: function() {
        //                var plusHref = "";
        //                if(typeof currentDate !== 'undefined') {
        //                    plusHref ="/date/" + currentDate
        //                }
        //                location.href = url + "printPdf/object/" + object + plusHref;
        //                $( this ).dialog( "close" );
        //            }
        //        }
        //    ]
        //});
        //dialogDiv.find(".button").on("click", function() {
        //    alert(url + "printPdf/object/" + object + "/" + $(this).data("href"));
        //    location.href = url + "printPdf/object/" + object + "/" + $(this).data("href");
        //});

        printTop.on("click", function() {
            print();
        });
        printBottom.on("click", function() {
            print();
        });

        $("div.print").on("click", function() {
            var plusHref = "";
            if(typeof currentDate !== 'undefined') {
                plusHref ="/date/" + currentDate
            }
            var place = '/object/' + params.object;
            if(typeof group !== 'undefined') {
                place = '/group/' + params.group;
            }
            location.href = params.url + "printPdf" + place + plusHref + $(this).data("href");
        });
    }

    function print() {
        //show dialog div


        //dialogDiv.dialog( "open" );
    }

    function changeDate(date, params) {
        params.container.lygym({
            "url" : params.url,
            "object" : params.object,
            "group" : params.group,
            "date" : date
        });
    }

    function createTooltips() {
        $(".item.reserved").each(function() {
            var element = $( this );
            var html = "";
            if(element.data("short") || element.data("name")) {
                if (element.data("short")) {
                    html += "<b>" + element.data("short") + "</b>";
                }
                if (element.data("name")) {
                    html += "<br />" + element.data("name");
                }
            } else {
                html += element.attr("title");
            }
            element.data("html", true);
            element.data("placement", "bottom");
            element.prop('title', html);
        });
        $(function () {
            $('.item').tooltip()
        });
        // $(".item").tooltip({
        //     track: true,
        //     content: function() {
        //         var element = $( this );
        //         var html = "";
        //         if(element.data("short") || element.data("name")) {
        //             if (element.data("short")) {
        //                 html += "<b>" + element.data("short") + "</b>";
        //             }
        //             if (element.data("name")) {
        //                 html += "<br />" + element.data("name");
        //             }
        //         } else {
        //             html += element.attr("title");
        //         }
        //         return html;
        //     }
        // });
    }

}( jQuery ));
